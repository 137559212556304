export default [
    {
        path: "dashboard",
        name: "owner.dashboard",
        meta: { 
            title: 'Owner Dashboard', 
            authRequired: true,
            roleAllowed: ['owner'],
            rolRedirect : [
                {
                    id : 'kasir',
                    name : 'cart'
                }
            ]
        },
        component: () => import("../views/outlet/home"),
    },
    {
        path: "category",
        name: "owner.category",
        meta: { 
            title: 'Category', 
            authRequired: true,
            roleAllowed: ['owner'],
            rolRedirect : [
                {
                    id : 'kasir',
                    name : 'cart'
                }
            ]
        },
        component: () => import("../views/outlet/category"),
    },
    {
        path: "product",
        name: "owner.product",
        meta: { 
            title: 'Product', 
            authRequired: true,
            roleAllowed: ['owner'],
            rolRedirect : [
                {
                    id : 'kasir',
                    name : 'cart'
                }
            ]
        },
        component: () => import("../views/outlet/product"),
    },
    {
        path: "product/store/:id?",
        name: "owner.product.store",
        meta: { 
            title: 'Product', 
            authRequired: true,
            roleAllowed: ['owner'],
            rolRedirect : [
                {
                    id : 'kasir',
                    name : 'cart'
                }
            ]
        },
        component: () => import("../views/outlet/product-store"),
    },
    {
        path: "kasir",
        name: "owner.kasir",
        meta: { 
            title: 'Manage Kasir', 
            authRequired: true,
            roleAllowed: ['owner'],
            rolRedirect : [
                {
                    id : 'kasir',
                    name : 'cart'
                }
            ]
        },
        component: () => import("../views/outlet/kasir"),
    },
    {
        path: "transaction/settlement",
        name: "transaction.settlement",
        meta: { 
            title: 'Settlement', 
            authRequired: true,
            roleAllowed: ['owner'],
            rolRedirect : [
                {
                    id : 'kasir',
                    name : 'cart'
                }
            ]
        },
        component: () => import("../views/transaction/settlement"),
    },
    {
        path: "/transaction/settlement/detail",
        name: "transaction.settlement.detail",
        meta: { 
            title: 'Settlement Detail', 
            authRequired: true,
            roleAllowed: ['owner'],
            rolRedirect : []
        },
        component: () => import("../views/transaction/settlement-detail"),
      },
]