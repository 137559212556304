// import store from "@/state/store";
import ownerRoute from './routes/owner.routes';
import kasirRoute from './routes/kasir.routes';

export default [
  {
    path: "/",
		redirect: '/dashboard',
  },
  {
    path: "/welcome",
		name: "welcome",
    meta: { 
        title: 'Welcome', 
        authRequired: false,
        roleAllowed: [],
        rolRedirect : [
            {
                id : 'kasir',
                name : 'cart'
            },
            {
              id : 'owner',
              name : 'owner.dashboard'
          }
        ]
    },
    component: () => import("./views/auth/welcome"),
  },
  {
    path: "/login-option",
		name: "login_option",
    meta: { 
        title: 'Login', 
        authRequired: false,
        roleAllowed: [],
        rolRedirect : [
            {
                id : 'kasir',
                name : 'cart'
            },
            {
              id : 'owner',
              name : 'owner.dashboard'
          }
        ]
    },
    component: () => import("./views/auth/login-option"),
  },
  {
    path: "/forgot-password",
		name: "forgot_password",
    meta: { 
        title: 'Lupa Password', 
        authRequired: false,
        roleAllowed: [],
        rolRedirect : [
            {
                id : 'kasir',
                name : 'cart'
            },
            {
              id : 'owner',
              name : 'owner.dashboard'
          }
        ]
    },
    component: () => import("./views/auth/forgot-password.vue"),
  },
  {
    path: "/reset-password/:token",
		name: "reset_password",
    meta: { 
        title: 'Reset Password', 
        authRequired: false,
        roleAllowed: [],
        rolRedirect : [
            {
                id : 'kasir',
                name : 'cart'
            },
            {
              id : 'owner',
              name : 'owner.dashboard'
          }
        ]
    },
    component: () => import("./views/auth/reset-password.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { 
        title: 'Dashboard', 
        authRequired: true,
        roleAllowed: [],
        rolRedirect : [
            {
                id : 'kasir',
                name : 'cart'
            },
            {
              id : 'owner',
              name : 'owner.dashboard'
          }
        ]
    },
    component: () => import("./views/test"),
  },
  {
    path: "/test",
    name: "test",
    meta: { authRequired: false},
    component: () => import("./views/test"),
  },
  //auth
  {
    path: "/login-owner",
    name: "login_owner",
    component: () => import("./views/auth/login-owner"),
    meta: { authRequired: false, title : 'Login Owner'},
  },
  {
    path: "/login-kasir",
    name: "login_kasir",
    component: () => import("./views/auth/login-kasir"),
    meta: { authRequired: false, title : 'Login Kasir'},
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/auth/register"),
    meta: {
      // beforeResolve(routeTo, routeFrom, next) {
      //   // If the user is already logged in
      //   if (store.getters["auth/loggedIn"]) {
      //     // Redirect to the home page instead
      //     next({ name: "default" });
      //   } else {
      //     // Continue to the login page
      //     next();
      //   }
      // },
    },
  },

  {
    path: "/account/home",
    name: "account.home",
    meta: { 
        title: 'Manage Account', 
        authRequired: true,
        roleAllowed: ['owner', 'kasir'],
        rolRedirect : []
    },
    component: () => import("./views/account/home"),
  },

  {
    path: "/account/profile",
    name: "account.profile",
    meta: { 
        title: 'Profile Account', 
        authRequired: true,
        roleAllowed: ['owner', 'kasir'],
        rolRedirect : []
    },
    component: () => import("./views/account/profile"),
  },

  {
    path: "/transaction/history",
    name: "transaction.history",
    meta: { 
        title: 'History Transaction', 
        authRequired: true,
        roleAllowed: ['owner', 'kasir'],
        rolRedirect : []
    },
    component: () => import("./views/transaction/history"),
  },
  {
    path: "/transaction/history/detail/:id?",
    name: "transaction.history.detail",
    meta: { 
        title: 'History Transaction Detail', 
        authRequired: true,
        roleAllowed: ['owner', 'kasir'],
        rolRedirect : []
    },
    component: () => import("./views/transaction/history-detail"),
  },

  // Owner Routes
  {
    path : '/owner',
    children : [...ownerRoute]
  },
  // transactions
  {
    path : '/kasir',
    children : [...kasirRoute]
  },

  {
      path: '/pages/404',
      name : '404',
      meta: { title: '404' },
      component: () => import('./views/utility/404.vue')
  },
  {
      path: '/pages/500',
      meta: { authRequired: true, title: '500' },
      component: () => import('./views/utility/500.vue')
  },

  {
		path: '/:pathMatch(.*)*',
		redirect: '/pages/404',
	},
];