import axios from 'axios';

import {useUserStore} from '../state/user';

const axiosAuth = axios.create();

axiosAuth.interceptors.request.use(async (request) => {
    const user = useUserStore();

    if(user.shouldRefresh) await user.refresh();

    let token = user.token;
    request.headers = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    return request
});

axiosAuth.interceptors.response.use(async (response) => {
    return response
}, async (error) => {
    const user = useUserStore();

    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        
        await user.refresh();

        let token = user.token;

        
        axiosAuth.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return axiosAuth(originalRequest);
    }
    if([401, 403].indexOf(error?.response?.status) > "-1") return await user.logout();

    return Promise.reject(error);
});


export default axiosAuth;