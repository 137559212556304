<template>
 <router-view></router-view>
</template>

<script>
import { useUserStore } from './state/user';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'App',
  components: {
  },

  methods : {
    ...mapActions(useUserStore, ['setShouldRefresh']),
  },
  computed : {
    ...mapState(useUserStore, ['user'])
  },
  async created() {
    if(this.user?.user_id) {
      await this.setShouldRefresh(true);
    }
  }
}
</script>
