import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

import router from '../router'

// const user = JSON.parse(localStorage.getItem('user'));
import axios from 'axios';
import axiosAuth from '../api/axiosAuth'
import appMixin from '../mixins/app.mixin';

const urlBackend = appMixin.data()._app_backendUrl;

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            user : useLocalStorage('user', {}),
            shouldRefresh : false
        }
    },
    getters: {
        token : (state) => state?.user?.token ?? '',
    },
    actions: {
        async login (auth, role = null) {
            let url = (role) ? urlBackend + '/api/v1/auth/login/' + role : urlBackend + '/api/v1/auth/login';
            let response = await axios.post(url, auth);
            
            if(!response?.data?.data?.user) throw {
                status : 'error',
                code : 500,
                message : 'Missing User'
            }
            
            let logged_user = {
                login: true,
                user_id: response.data.data.user.user_id,
                name: response.data.data.user.nama_lengkap,
                phone_number : response.data.data.user.phone_number,
                kode_kasir : response.data.data.user?.kode_kasir ?? null,
                email: response.data.data.user.email,
                role : response.data.data.user?.role ?? null,
                token: response.data.data.authorization.token,
                merchant : {
                    merchant_name : response.data.data?.merchant?.merchant_name ?? null
                },
                last_login_at : response.data.data.user?.last_login_at ?? null
            }
            await this.setUser(logged_user);
            return logged_user;
        },

        async register (auth) {
            let response = await axios.post(urlBackend + '/api/v1/auth/signup-owner', auth);
            
            if(!response?.data?.data?.user) throw {
                status : 'error',
                code : 500,
                message : 'Missing User'
            }
            
            let logged_user = {
                login: true,
                user_id: response.data.data.user.user_id,
                name: response.data.data.user.nama_lengkap,
                phone_number : response.data.data.user.phone_number,
                kode_kasir : response.data.data.user?.kode_kasir ?? null,
                email: response.data.data.user.email,
                role : response.data.data.user?.role ?? null,
                token: response.data.data.authorization.token,
                merchant : {
                    merchant_name : response.data.data?.merchant?.merchant_name ?? null
                },
                last_login_at : response.data.data.user?.last_login_at ?? null
            }
            await this.setUser(logged_user);
            return logged_user;
        },

        async refresh() {
            try {
                this.setShouldRefresh(false);
                let response = await axios.post(urlBackend + '/api/v1/auth/refresh', {}, {
                    headers : {
                        'Authorization' : 'Bearer ' + this.token
                    }
                });
                if(!response?.data?.data?.user) return router.push({ name : 'welcome'});
                
                const logged_user = {
                    login: true,
                    user_id: response.data.data.user.user_id,
                    name: response.data.data.user.nama_lengkap,
                    phone_number : response.data.data.user.phone_number,
                    kode_kasir : response.data.data.user?.kode_kasir ?? null,
                    email: response.data.data.user.email,
                    role : response.data.data.user?.role ?? null,
                    token: response.data.data.authorization.token,
                    merchant : {
                        merchant_name : response.data.data?.merchant?.merchant_name ?? this.user?.merchant?.merchant_name ?? null
                    },
                    last_login_at : response.data.data.user?.last_login_at ?? null
                }
                await this.setUser(logged_user);
                return logged_user;
            } catch (err) {
                this.logout();
            }
        },

        async syncLastLogin(){
            let response = await axiosAuth.get(urlBackend + '/api/v1/auth/last-login');

            let lastLogin = response?.data?.data?.last_login?.last_login_at ?? null;
            if(!lastLogin) return false;

            let user = this.user;
            if(!user) user = JSON.parse(localStorage.getItem('user'));

            user.last_login_at = lastLogin;
            await this.setUser(user);
        },

        async setUser(localStorageUser){
            this.user = localStorageUser;
            localStorage.setItem('user', JSON.stringify(localStorageUser));
        },

        async updateUser(user) {
            this.user = {...this.user, ...user};
            localStorage.setItem('user', JSON.stringify(this.user));
        },

        setShouldRefresh(isShouldRefresh = false) {
            this.shouldRefresh = isShouldRefresh
        },

        async logout() {
            localStorage.removeItem('user');
            this.user = {};
            return router.push({ name : 'welcome', query: { redirectFrom: router.currentRoute.value.fullPath } });
        }
    },
})