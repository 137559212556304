import { createWebHistory, createRouter } from "vue-router";

// import VueMeta from 'vue-meta'

// import store from '@/state/store'
import routes from './routes'
import {useUserStore} from '../state/user';

// Vue.use(VueRouter)
// Vue.use(VueMeta, {
//   // The component option name that vue-meta looks for meta info on.
//   keyName: 'page',
// })

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  if (routeTo.meta.title != undefined) {
      document.title = routeTo.meta.title + " | " + (process.env.VUE_APP_NAME ?? 'SOFTWARE_SEKOLAH_CASHLESS').replace(/_/g, ' ');
  }
  document.body.style = ''; // Fix overflow always hidden when open the sidebar, impact if not using this => cannot scroll content
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  if (!authRequired) return next();

  const {user, logout} = useUserStore();

  if(!user) return next({ name: 'welcome', query: { redirectFrom: routeTo.fullPath } });

  if(!user?.token) {
    logout();
    return next({ name: 'welcome', query: { redirectFrom: routeTo.fullPath } });
  }
  // Check If Role Defined, and Manage redirect each role
  if(!routeTo?.meta?.roleAllowed) return next();
  let rolUser = user?.role;
  if(routeTo?.meta?.roleAllowed.indexOf(rolUser) > "-1") return next();

  let rolRedirect = routeTo?.meta?.rolRedirect ?? [];
  let redirect = rolRedirect.filter((r) => r.id == rolUser)?.[0] ?? {};

  return next({name : redirect?.name ?? '404'});
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

export default router
