export default [
    {
        path: "product/list",
        name: "product_list",
        meta: { 
            title: 'Product List', 
            authRequired: true,
            roleAllowed: ['kasir', 'owner'],
            rolRedirect : [
                {
                    id : 'owner',
                    name : 'owner.dashboard'
                }
            ]
        },
        component: () => import("../views/product/list"),
    },
    {
        path: "transaction/cart",
        name: "cart",
        meta: { 
            title: 'Cart Kasir', 
            authRequired: true,
            roleAllowed: ['kasir', 'owner'],
            rolRedirect : [
                {
                    id : 'owner',
                    name : 'owner.dashboard'
                }
            ]
        },
        component: () => import("../views/transaction/cart"),
    },

    {
        path: "transaction/process",
        name: "transaction",
        meta: { 
            title: 'Transaction Process', 
            authRequired: true,
            roleAllowed: ['kasir', 'owner'],
            rolRedirect : [
                {
                    id : 'owner',
                    name : 'owner.dashboard'
                }
            ]
        },
        component: () => import("../views/transaction/transaction"),
    },
    {
        path: "transaction/success/:id",
        name: "transaction_success",
        meta: { 
            title: 'Transaction Success', 
            authRequired: true,
            roleAllowed: ['kasir', 'owner'],
            rolRedirect : [
                {
                    id : 'owner',
                    name : 'owner.dashboard'
                }
            ]
        },
        component: () => import("../views/transaction/success"),
    },
    {
        path: "transaction/scanner",
        name: "transaction_scanner",
        meta: { 
            title: 'Transaction Scanner', 
            authRequired: true,
            roleAllowed: ['kasir', 'owner'],
            rolRedirect : [
                {
                    id : 'owner',
                    name : 'owner.dashboard'
                }
            ]
        },
        component: () => import("../views/transaction/scanner"),
    },
    {
        path: "transaction/pin",
        name: "transaction_pin",
        meta: { 
            title: 'Transaction PIN', 
            authRequired: true,
            roleAllowed: ['kasir', 'owner'],
            rolRedirect : [
                {
                    id : 'owner',
                    name : 'owner.dashboard'
                }
            ]
        },
        component: () => import("../views/transaction/input-pin"),
    },
    {
        path: "product/stock",
        name: "product.stock",
        meta: { 
            title: 'Product Stock',  
            authRequired: true,
            roleAllowed: ['kasir', 'owner'],
            rolRedirect : [
                {
                    id : 'owner',
                    name : 'owner.dashboard'
                }
            ]
        },
        component: () => import("../views/outlet/product-stock"),
    },
]